/* Halcyon Black Fonts */
@font-face {
  font-family: "Halcyon Black";
  src: url("../public/Halcyon/black.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Black";
  src: url("../public/Halcyon/blackitalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Bold Fonts */
@font-face {
  font-family: "Halcyon Bold";
  src: url("../public/Halcyon/bold.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Bold";
  src: url("../public/Halcyon/bolditalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Hairline Fonts */
@font-face {
  font-family: "Halcyon Hairline";
  src: url("../public/Halcyon/hairline.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Hairline";
  src: url("../public/Halcyon/hairlineitalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Light Fonts */
@font-face {
  font-family: "Halcyon Light";
  src: url("../public/Halcyon/light.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Light";
  src: url("../public/Halcyon/lightitalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Medium Fonts */
@font-face {
  font-family: "Halcyon Medium";
  src: url("../public/Halcyon/medium.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Medium";
  src: url("../public/Halcyon/mediumitalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Regular Fonts */
@font-face {
  font-family: "Halcyon Regular";
  src: url("../public/Halcyon/regular.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Regular";
  src: url("../public/Halcyon/regularitalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Semibold Fonts */
@font-face {
  font-family: "Halcyon Semibold";
  src: url("../public/Halcyon/semibold.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Semibold";
  src: url("../public/Halcyon/semibolditalic.otf") format("otf");
  font-weight: normal;
  font-style: italic;
}
/* Halcyon Thin Fonts */
@font-face {
  font-family: "Halcyon Thin";
  src: url("../public/Halcyon/thin.otf") format("otf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Halcyon Thin";
  src: url("../public/Halcyon/alternative/thinitalic.ttf") format("ttf");
  font-weight: 100;
  font-style: italic;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Halcyon", sans-serif;
}
body {
  scroll-behavior: smooth;
}
img,
video {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}

.text-outline {
  -webkit-text-stroke: 1px #323a64;
  text-stroke: 2px #323a64;
  color: transparent;
}

.text-shadow {
  color: #323a64;
  font-size: 30px;
  font-weight: bold;
  text-shadow: 2px 2px 0 #fff, 2.7px 2.7px 0 #000;
}

.text-shadow-rahmen {
  color: #333c3a;
  font-size: 22px;
  font-weight: bold;
  text-shadow: 2px 2px 0 #fff, 2.7px 2.7px 0 #000;
}

